
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { AppRoutes } from './app.routing';
import { AppComponent } from './app.component';

import { FlexLayoutModule } from '@angular/flex-layout';
import { FullComponent } from './layouts/full/full.component';
import { AppBlankComponent } from './layouts/blank/blank.component';

import { VerticalAppHeaderComponent } from './layouts/full/vertical-header/vertical-header.component';
import { VerticalAppSidebarComponent } from './layouts/full/vertical-sidebar/vertical-sidebar.component';
import { HorizontalAppHeaderComponent } from './layouts/full/horizontal-header/horizontal-header.component';
import { HorizontalAppSidebarComponent } from './layouts/full/horizontal-sidebar/horizontal-sidebar.component';

import { AppBreadcrumbComponent } from './layouts/full/breadcrumb/breadcrumb.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DemoMaterialModule } from './demo-material-module';


import { SharedModule } from './shared/shared.module';
import { SpinnerComponent } from './shared/spinner.component';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { PackComponent } from './pack/pack.component';
// import { PropositionComponent } from './proposition/proposition.component';
// import { PropositionListComponent } from './proposition/proposition-list/proposition-list.component';
// import { BannerCreativesComponent } from './banner-creatives/banner-creatives.component';
// import { BannerCreativesComponent } from './banner-creatives/banner-creatives.component';
// import { Banner1Component } from './banner-creatives/banner1/banner1.component';
// import { Banner84Component } from './banner-creatives/banner84/banner84.component';
// import { Banner2Component } from './banner-creatives/banner2/banner2.component';
import { GoogleMapsModule } from '@angular/google-maps'
import { BannerCreativesModule } from './banner-creatives/banner-creatives.module';
// import { GlobalPublicComponent } from './global-public/global-public.component';
// import { PackAdvertiserComponent } from './pack-advertiser/pack-advertiser.component';
// import { SharedService } from './service/shared.service';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 2,
  wheelPropagation: true
};

@NgModule({
  declarations: [
    AppComponent,
    FullComponent,
    VerticalAppHeaderComponent,
    SpinnerComponent,
    AppBlankComponent,
    VerticalAppSidebarComponent,
    AppBreadcrumbComponent,
    HorizontalAppHeaderComponent,
    HorizontalAppSidebarComponent,
    PackComponent,
    // GlobalPublicComponent,
    // PackAdvertiserComponent,
    // PropositionComponent,
    // PropositionListComponent,
    // Banner2Component,
    // BannerCreativesComponent,
    // BannerCreativesComponent,
    // Banner1Component,
    // Banner84Component
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    DemoMaterialModule,
    FormsModule,
    FlexLayoutModule,
    PerfectScrollbarModule,
    HttpClientModule,
    SharedModule,
    RouterModule.forRoot(AppRoutes),
    GoogleMapsModule,
    HttpClientModule,
    // BannerCreativesModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    // SharedService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
