import { Routes } from '@angular/router';

import { FullComponent } from './layouts/full/full.component';

export const AppRoutes: Routes = [
    {
        path: 'global-reporting/:multiKeyProp',
        loadChildren: () => import('./global-public/global-public.module').then(m => m.GlobalPublicModule)
    },
    {
        path: '',
        component: FullComponent,
        children: [
            {
                path: '',
                redirectTo: '/campaigns',
                pathMatch: 'full'
            },
            {
                path: 'material',
                loadChildren: () => import('./material-component/material.module').then(m => m.MaterialComponentsModule)
            },
            {
                path: 'dashboard',
                loadChildren: () => import('./starter/starter.module').then(m => m.StarterModule)
            },
            {
                path: 'pack',
                loadChildren: () => import('./pack/pack.module').then(m => m.PackModule)
            },
            {
                path: 'galleryCreation',
                loadChildren: () => import('./gallery/gallery.module').then(m => m.GalleryModule)
            },
            {
                path: 'campaigns',
                loadChildren: () => import('./campaigns/campaigns.module').then(m => m.CampaignsModule)
            },
            {
                path: 'welcome',
                loadChildren: () => import('./welcome-page/welcome-page.module').then(m => m.WelcomePageModule)
            },
            {
                path: 'login',
                loadChildren: () => import('./login/login.module').then(m => m.LoginModule)
            },
            {
                path: 'proposition',
                loadChildren: () => import('./proposition/proposition.module').then(m => m.PropositionModule)
            },
            {
                path: 'banners/:idGallery/:idCompany/:advLocId',
                loadChildren: () => import('./banner-creatives/banner-creatives.module').then(m => m.BannerCreativesModule)
            },
            {
                path: 'audience',
                redirectTo: 'pack/pack-audience',
                pathMatch: 'full'
            },
            {
                path: 'stripe-redirect/:keyProp/:stripeSesion',
                loadChildren: () => import('./stripe-redirect/stripe-redirect.module').then(m => m.StripeRedirectModule)
            },
            {
                path: 'dynamic_banner_ads.html/:idCompany/:idGallery/:keyCampaign',
                loadChildren: () => import('./dynamic-banner-ads/dynamic-banner-ads.module').then(m => m.DynamicBannerAdsModule)
            },

        ]
    }
];
