import {Component, ElementRef, EventEmitter, Input, OnDestroy, Output, NgZone, OnInit} from '@angular/core';
import * as Highcharts from 'highcharts';

@Component({
  selector: 'app-high-chart',
  template: ''
})
export class HighChartComponent implements OnDestroy {

  @Input() Highcharts: typeof Highcharts | any;
  @Input() constructorType: string | any;
  @Input() callbackFunction: Highcharts.ChartCallbackFunction | any;
  @Input() oneToOne: boolean | any;
  @Input() runOutsideAngular: boolean | any;

  @Input() set options(val: Highcharts.Options) {
    if (val === undefined) {
      return ;
    }
    this.optionsValue = val;
    this.wrappedUpdateOrCreateChart();
  }
  @Input() set update(val: boolean) {
    if (val) {
      this.wrappedUpdateOrCreateChart();
      this.updateChange.emit(false);
    }
  }

  @Output() updateChange = new EventEmitter<boolean>(true);
  @Output() chartInstance  = new EventEmitter<Highcharts.Chart>();

  private chart: Highcharts.Chart | any;
  private optionsValue: Highcharts.Options | undefined;

  constructor(
    private el: ElementRef,
    private _zone: NgZone
  ) {}

  wrappedUpdateOrCreateChart() {
    if (this.runOutsideAngular) {
      this._zone.runOutsideAngular(() => {
        this.updateOrCreateChart();
      });
    } else {
      this.updateOrCreateChart();
    }
  }

  updateOrCreateChart() {
    if (this.chart && this.chart.update) {
      this.chart.update(this.optionsValue, true, this.oneToOne || false);
    } else {
      this.chart = (this.Highcharts as any)[this.constructorType || 'chart'](
        this.el.nativeElement,
        this.optionsValue,
        this.callbackFunction || null
      );

      // emit chart instance on init
      this.chartInstance.emit(this.chart);
    }
  }

  ngOnDestroy() {
    if (this.chart) {
      this.chart.destroy();
      this.chart = null;
    }
  }

}
