import { Component } from '@angular/core';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from 'src/app/service/common.service';
import { SharedService } from 'src/app/service/shared.service';

@Component({
  selector: 'app-horizontal-header',
  templateUrl: './horizontal-header.component.html',
  styleUrls: []
})
export class HorizontalAppHeaderComponent {
  public config: PerfectScrollbarConfigInterface = {};


  // This is for Notifications
  notifications: Object[] = [
    {
      round: 'round-danger',
      icon: 'ti-link',
      title: 'Luanch Admin',
      subject: 'Just see the my new admin!',
      time: '9:30 AM'
    },
    {
      round: 'round-success',
      icon: 'ti-calendar',
      title: 'Event today',
      subject: 'Just a reminder that you have event',
      time: '9:10 AM'
    },
    {
      round: 'round-info',
      icon: 'ti-settings',
      title: 'Settings',
      subject: 'You can customize this template as you want',
      time: '9:08 AM'
    },
    {
      round: 'round-primary',
      icon: 'ti-user',
      title: 'Pavan kumar',
      subject: 'Just see the my admin!',
      time: '9:00 AM'
    }
  ];

  // This is for Mymessages
  mymessages: Object[] = [
    {
      useravatar: 'assets/images/users/1.jpg',
      status: 'online',
      from: 'Pavan kumar',
      subject: 'Just see the my admin!',
      time: '9:30 AM'
    },
    {
      useravatar: 'assets/images/users/2.jpg',
      status: 'busy',
      from: 'Sonu Nigam',
      subject: 'I have sung a song! See you at',
      time: '9:10 AM'
    },
    {
      useravatar: 'assets/images/users/2.jpg',
      status: 'away',
      from: 'Arijit Sinh',
      subject: 'I am a singer!',
      time: '9:08 AM'
    },
    {
      useravatar: 'assets/images/users/4.jpg',
      status: 'offline',
      from: 'Pavan kumar',
      subject: 'Just see the my admin!',
      time: '9:00 AM'
    }
  ];

  public selectedLanguage: any = {
    language: 'Français',
    code: 'fr',
    type: 'fr',
    icon: 'fr'
  }

  public languages: any[] = [
    {
      language: 'Français',
      code: 'fr',
      icon: 'fr'
    }, {
      language: 'English',
      code: 'en',
      type: 'US',
      icon: 'us'
    },
  ]
  id_agency: string | null | undefined;
  id_advertiser: string | null | undefined;
  name: string | null | undefined;
  email: string | null | undefined;
  type_intervenant: any;
  company_name: any;

  constructor(private translate: TranslateService, public commonService: CommonService, private sharedService: SharedService) {
    translate.setDefaultLang('fr');
    this.sharedService.state$.subscribe(newState => {      
      if(newState == 'setCookie'){
        this.id_agency = (this.commonService.decodeAccessToken().type_intervenant == 2)?this.commonService.decodeAccessToken().id_intervenant:'';
        this.id_advertiser = (this.commonService.decodeAccessToken().type_intervenant == 1)?this.commonService.decodeAccessToken().id_intervenant:'';
        this.name = this.commonService.decodeAccessToken().contact_name
        this.email = this.commonService.decodeAccessToken().contact_email
        this.type_intervenant = this.commonService.decodeAccessToken().type_intervenant
        this.company_name = this.commonService.decodeAccessToken().company_name
      }
    });
    this.id_agency = (this.commonService.decodeAccessToken().type_intervenant == 2)?this.commonService.decodeAccessToken().id_intervenant:'';
    this.id_advertiser = (this.commonService.decodeAccessToken().type_intervenant == 1)?this.commonService.decodeAccessToken().id_intervenant:'';
    this.name = this.commonService.decodeAccessToken().contact_name
    this.email = this.commonService.decodeAccessToken().contact_email
    this.type_intervenant = this.commonService.decodeAccessToken().type_intervenant
    this.company_name = this.commonService.decodeAccessToken().company_name

  }

  changeLanguage(lang: any) {
    this.translate.use(lang.code)
    this.selectedLanguage = lang;
  }

  logout(){
    this.commonService.logout()
  }
}
