import { Injectable } from '@angular/core';

export interface BadgeItem {
    type: string;
    value: string;
}
export interface Saperator {
    name: string;
    type?: string;
}
export interface ChildrenItems {
    state: string;
    name: string;
    type?: string;
}

export interface Menu {
    state: string;
    name: string;
    type: string;
    icon: string;
    badge?: BadgeItem[];
    saperator?: Saperator[];
    children?: ChildrenItems[];
}
const isLoggedIn = true; // Replace with the actual login state
let MENUITEMS: Menu[] = [];
if (!isLoggedIn) {
    MENUITEMS = []; // Empty the array if the user is not logged in
}else{
    // MENUITEMS = [
    //     {
    //         state: 'campaigns',
    //         name: 'Campagnes',
    //         type: 'link',
    //         icon: 'settings'
    //     },
    //     {
    //         state: 'galleryCreation',
    //         name: 'Gallery Creation',
    //         type: 'link',
    //         icon: 'image'
    //     },
    //     {
    //         state: 'audience',
    //         name: 'Audience',
    //         type: 'link',
    //         icon: 'people'
    //     },
    // ];
}

@Injectable()
export class HorizontalMenuItems {
    getMenuitem(): Menu[] {
        return MENUITEMS;
    }
}
