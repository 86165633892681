import {
  ChangeDetectorRef,
  Component,
  NgZone,
  OnDestroy,
  ViewChild,
  HostListener,
  Directive,
  AfterViewInit
} from '@angular/core';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { MediaMatcher } from '@angular/cdk/layout';
import { SharedService } from '../../../service/shared.service';
import { Subscription } from 'rxjs';
import { CommonService } from 'src/app/service/common.service';
import { PropositionService } from 'src/app/proposition/service/proposition.service';

@Component({
  selector: 'app-horizontal-sidebar',
  templateUrl: './horizontal-sidebar.component.html',
  styleUrls: []
})

export class HorizontalAppSidebarComponent implements OnDestroy {
  public config: PerfectScrollbarConfigInterface = {};
  mobileQuery: MediaQueryList;

  private _mobileQueryListener: () => void;
  receivedData: string | undefined;
  subscription: Subscription;
  menuItems: { state: string; name: string; type: string; icon: string; }[] | undefined;
  isLoggedIn: boolean = false;
  showheader: number = 0;
  advListData:any      =[];

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private sharedService: SharedService,
    public commonService: CommonService,
    private readonly _apiRequestService: PropositionService,
  ) {
    this.mobileQuery = media.matchMedia('(min-width: 1024px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
    if (this.commonService.decodeAccessToken().type_intervenant == 2) {
      this.agency_menu();
    } else if (this.commonService.decodeAccessToken().type_intervenant == 1) {
      this.advertiser_menu();
    } else {
      this.menuItems = [];
    }

    this.subscription = this.sharedService.state$.subscribe(data => {
      if (data == 'first_login') {
        this.menuItems = [];
      } else if (data == 'advertiser_login') {
        this.advertiser_menu();
      } else if (data == 'agency_login') {

        this.agency_menu();
      }
    });

  }

  advertiser_menu() {
    this.menuItems = [
      {
        state: 'proposition',
        name: 'Voir toutes les activations',
        type: 'link',
        icon: 'settings'
      },
      {
        state: 'galleryCreation',
        name: 'Gallery Creation',
        type: 'link',
        icon: 'image'
      },
    ];
  }

  agency_menu() {
    this.advListData.push(this.commonService.decodeAccessToken().id_intervenant);
    let postData = {
      "id_intervenant":this.advListData,
      "type_intervenant": this.commonService.decodeAccessToken().type_intervenant,
      "start_date": '',
      "end_date": '',
      "tab": 1,
      "search": ''
    }  
    this._apiRequestService.getPropositions$(postData).subscribe((result) => {
      if (result.data.propositionList[0]) {
        this.menuItems = [
          {
            state: 'proposition',
            name: 'Voir toutes les activations',
            type: 'link',
            icon: 'settings'
          },
          {
            state: 'campaigns',
            name: 'Campagnes',
            type: 'link',
            icon: 'settings'
          },
          {
            state: 'galleryCreation',
            name: 'Gallery Creation',
            type: 'link',
            icon: 'image'
          },
          {
            state: 'audience',
            name: 'Audience',
            type: 'link',
            icon: 'people'
          },
        ];
      } else {
        this.menuItems = [
          {
            state: 'campaigns',
            name: 'Campagnes',
            type: 'link',
            icon: 'settings'
          },
          {
            state: 'galleryCreation',
            name: 'Gallery Creation',
            type: 'link',
            icon: 'image'
          },
          {
            state: 'audience',
            name: 'Audience',
            type: 'link',
            icon: 'people'
          },
        ];
      }
    })

  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }
}
