import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CommonService } from 'src/app/service/common.service';
 
@Injectable({
  providedIn: 'root'
})
export class PropositionService {
  constructionTableCount    = new BehaviorSubject<number>(0);
  diffusionTableCount       = new BehaviorSubject<number>(0);
  terminatedTableCount      = new BehaviorSubject<number>(0);
  propositionFilterDate     = new BehaviorSubject<any>([]);
  customDate                = new BehaviorSubject<any>([]);
  propositionSearch         = new BehaviorSubject<string>('');
  tabChanged                = new BehaviorSubject<boolean>(false);
  isMultiProposition        = new BehaviorSubject<boolean>(false);
  constructor(private readonly _httpService: CommonService) { }

  getPropositions$(data:any): Observable<any> {
    return this._httpService.post('packPreview/propositionList',data)
  }

  propositionCampaign$(data:any): Observable<any> {
    return this._httpService.get('packPreview/propositionCampaign/'+data)
  }
  deleteProposition$(_id: number) {
    return this._httpService.delete(`proposition/${_id}`)
  }

  getStandardAdvertiserList(data: string){
    return this._httpService.get('client/getStandardAdvertiserList/'+data)
  }

  getAdvertisers(data: number)
  {
    return this._httpService.get('packPreview/'+data+'/allAdvertiser');
  }

  allMultiProposition$(data:any): Observable<any> {
    return this._httpService.post('packPreview/allMultiProposition',data)
  }

  multiPropositionList$(data:any): Observable<any> {
    return this._httpService.post('packPreview/multiPropositionList',data)
  }

  deleteMultiProposition$(_id: number) {
    return this._httpService.delete(`packPreview/multiPropositionList/${_id}`)
  }
}

