import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
providedIn: 'root'
})
export class SharedService {
private stateSubject = new Subject<string>();
state$ = this.stateSubject.asObservable();

updateState(newState: string) {
this.stateSubject.next(newState);
}
}