import { MediaMatcher } from '@angular/cdk/layout';
import { NavigationEnd, Router } from '@angular/router';
import {
	ChangeDetectorRef,
	Component,
	ElementRef,
	HostListener,
	OnDestroy,
	OnInit,
	ViewChild
} from '@angular/core';
import { MenuItems } from '../../shared/menu-items/menu-items';


import { PerfectScrollbarConfigInterface, PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { CommonService } from 'src/app/service/common.service';
import { environment } from 'src/environments/environment';
import { PackService } from 'src/app/pack/service/pack.service';
import { Title } from '@angular/platform-browser';


/** @title Responsive sidenav */
@Component({
	selector: 'app-full-layout',
	templateUrl: 'full.component.html',
	styleUrls: []
})
export class FullComponent implements OnDestroy {
	@ViewChild('horizontalsidebar') horizontalsidebar !: ElementRef;
	@ViewChild('header') header !: ElementRef;
	mobileQuery: MediaQueryList;

	dir = 'ltr';
	dark = false;
	minisidebar = false;
	boxed = false;
	horizontal = true;

	green = false;
	blue = false;
	danger = false;
	showHide = false;
	url = '';
	sidebarOpened = false;
	status = false;
    headerBar: boolean | undefined;

	public showSearch = false;
	public config: PerfectScrollbarConfigInterface = {};
	// tslint:disable-next-line - Disables all
	private _mobileQueryListener: () => void;
	bridgeUrl: string | undefined;

	constructor(
		public router: Router,
		changeDetectorRef: ChangeDetectorRef,
		media: MediaMatcher,
		public menuItems: MenuItems,
		private matIconRegistry: MatIconRegistry,
		private domSanitizer: DomSanitizer,
		public commonService: CommonService,
		private packService : PackService,
		private titleService: Title

	) {
		this.mobileQuery = media.matchMedia('(min-width: 1100px)');
		this._mobileQueryListener = () => changeDetectorRef.detectChanges();
		// tslint:disable-next-line: deprecation
		this.mobileQuery.addListener(this._mobileQueryListener);
		// this is for dark theme
		// const body = document.getElementsByTagName('body')[0];
		// body.classList.toggle('dark');
		this.dark = false;

		this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                if(event.url.includes('welcome') || event.url.includes('login')){
                    this.headerBar=false;
                  }else{
					if(!this.commonService.decodeAccessToken().id_intervenant){
						console.log('asdfasfdfsad');
						this.headerBar=false;
						this.commonService.redirectToRoute('login')
					}else{
						this.headerBar=true;
					}
                  }
            }
        });
	}

	ngOnDestroy(): void {
		// tslint:disable-next-line: deprecation
		this.mobileQuery.removeListener(this._mobileQueryListener);
	}


	ngOnInit() {
		this.titleService.setTitle("Solocal");
		this.setFavicon('favicon.ico');
		this.bridgeUrl = environment.bridgeRedirect;
		//const body = document.getElementsByTagName('body')[0];
		// body.classList.add('dark');
	}

	clickEvent(): void {
		this.status = !this.status;
	}

	darkClick() {
		// const body = document.getElementsByTagName('body')[0];
		// this.dark = this.dark;
		const body = document.getElementsByTagName('body')[0];
		body.classList.toggle('dark');
		// if (this.dark)
		// else
		// 	body.classList.remove('dark');
		// this.dark = this.dark;
		// body.classList.toggle('dark');
		// this.dark = this.dark;
	}

	svgicons() {
		this.matIconRegistry.addSvgIcon(
			`facebook`,
			this.domSanitizer.bypassSecurityTrustResourceUrl("./assets/images/svg/facebook.svg")
		  );

	}

	onScroll(){
		this.checkHeaderVisibility();
	}
	
	private setFavicon(href: string): void {
		const link: HTMLLinkElement | null = document.querySelector('#dynamic-favicon');
		if (link) {
		  link.href = href;
		} else {
		  const newLink: HTMLLinkElement = document.createElement('link');
		  newLink.id = 'dynamic-favicon';
		  newLink.rel = 'icon';
		  newLink.href = href;
		  document.head.appendChild(newLink);
		}
	  }


	@HostListener('window:scroll', ['$event'])
	checkHeaderVisibility() {
		const headerElement = document.getElementById('header');
		if (headerElement) {
			const rect = headerElement.getBoundingClientRect();

			// Check if the header is within the viewport
			const isVisible = rect.top >= 0 && rect.bottom <= window.innerHeight;
			if (isVisible) {
				// Header is visible on the screen
				this.packService.setHeaderVisibillity(true);
			} else{
				this.packService.setHeaderVisibillity(false);
			}
		} else {
			// Handle the case where the element with the given ID is not found
			console.log(`Element with ID ${headerElement} not found`);
		}
	}

}
