export const environment = {
    production: false,
    baseUrl: 'https://dev-localpub.solocal.com',
    apiUrl: 'https://dev-localpub-api.solocal.com',
    bridgeRedirect:'https://qa-rct-v3-bridge.dev.leadformance.com/',
    publicPage:'https://devreporting-localpub.solocal.com',
    globalPublic:'https://devbo.theramp.co/global-public-',
    RampUrl:"https://devbo.theramp.co/",
    projectServer:'2',
    googleApiKey : 'AIzaSyCYXX9YJm97Nhkz6j_EtN4eLMujnYd4DN8',
    RampData:"https://assets.rampdata.fr/"


  };