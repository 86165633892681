import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { CommonService } from 'src/app/service/common.service';
@Injectable({
  providedIn: 'root'
})

export class PackService {
  key_pack                  = new BehaviorSubject<string>('');
  id_pack                   = new BehaviorSubject<number>(0);
  idGalleryGroup            = new BehaviorSubject<number>(0);
  savedLevierStatus         = new BehaviorSubject<boolean>(false);
  savedAudienceStatus       = new BehaviorSubject<boolean>(false);
  packSavingData            = new BehaviorSubject<Array<packInfo>>([]);
  dynamicBudgetValue        = new BehaviorSubject<number>(0);
  budgetCampaignsData       = new BehaviorSubject([]);
  is_prix_fix               = new BehaviorSubject<number>(0);
  cibilageExsits            = new BehaviorSubject<boolean>(false);
  packlevier                = new BehaviorSubject<Array<packlevier>>([]);
  BudgetStatus              = new BehaviorSubject<boolean>(false);
  idAudience                = new BehaviorSubject<number>(0);
  isEditKeyPack             = new BehaviorSubject<string>('');
  showBlock                 = new BehaviorSubject([]);
  savedAudienceId           = new BehaviorSubject<number>(0);
  savedThematicId           = new BehaviorSubject<number>(0);
  advertiserPopulation      = new BehaviorSubject<number>(0);
  multiactivationdata       = new BehaviorSubject({});
  pageLoading               = new BehaviorSubject<boolean>(false);
  HeaderVisible             = new BehaviorSubject<boolean>(true);
  private validDate         : Subject<boolean> = new Subject<boolean>();
  selectedLevier            = new BehaviorSubject<number>(0);

  constructor(private readonly _httpService: CommonService) { 
    this.validDate.next(false);
  }
  saveBasicDetails$(data:any): Observable<any> {
    return this._httpService.post('packCreation/packDetails',data)
  }

  getPackId$(keyPack:any): Observable<any> {
    return this._httpService.get(`packPreview/${keyPack}/packId`)
  }

  getPackDetails$(keyPack:any): Observable<any> {
    return this._httpService.get(`packPreview/${keyPack}`)
  }

  getAudienceData$(): Observable<any> {
    return this._httpService.get(`packPreview/allAudienceList`)
  }

  saveAudience$(data:any): Observable<any> {
    return this._httpService.post(`packCreation/ciblage`,data)
  }

  getSavedAudience$(id_intervenant: number): Observable<any> {
    return this._httpService.get(`packCreation/ciblageByIntervenant/${id_intervenant}`)
  }

  getSavedAudienceById$(idCiblagePredefined:any): Observable<any> {
    return this._httpService.get(`packCreation/ciblage/${idCiblagePredefined}`)
  }

  deletePredefinedCiblage$(idCiblagePredefined:any): Observable<any> {
    return this._httpService.delete(`packCreation/ciblage/${idCiblagePredefined}`)
  }

  UpdatePackCiblage$(data:any): Observable<any> {
    return this._httpService.post(`packCreation/packCiblage`,data)
  }

  getPackAudienceList$(id_pack:Number): Observable<any> {
    return this._httpService.get(`packPreview/${id_pack}/packCiblage`)
  }

  // Levier Part API starts
  getCampaignList$(key_pack: string): Observable<any> {
    return this._httpService.get(`packPreview/campaignList/${key_pack}`)
  }

  getLevier$(): Observable<any> {
    return this._httpService.get(`leviers`)
  }

  getRemuneration$(id_levier: number): Observable<any> {
    return this._httpService.get(`packPreview/renumeration/${id_levier}`)
  }

  getFormatLevier$(id_levier: number, id_remuneration: number,id_intervenant:number) {
    return this._httpService.get(`packPreview/format/${id_levier}/${id_remuneration}/${id_intervenant}`)
  }

  getInventoryLevier$(id_levier: number, id_remuneration: number) {
    return this._httpService.get(`packPreview/inventaire/${id_levier}/${id_remuneration}`)
  }

  getPlacementLevier$(id_levier: number, id_remuneration: number) {
    return this._httpService.get(`packPreview/placement/${id_levier}/${id_remuneration}`)
  }

  saveCampaign$(data: any) {
    return this._httpService.post(`packCreation/campaign`, data)
  }

  getCampaignById$(_id: number) {
    return this._httpService.get(`packPreview/campaign/${_id}`)
  }

  deleteCampaign$(_id: number) {
    return this._httpService.delete(`packCreation/campaign/${_id}`)
  }
  // Levier Part API Ends

  getGroupName$(): Observable<any> {
    return this._httpService.get('gallery-groups')
  }

  getGroupNamePost$(data:any): Observable<any> {
    return this._httpService.post('gallery-groups-sort',data)
  }

  savePackRedirectionInfo$(data:any): Observable<any> {
    return this._httpService.post(`packCreation/redirection`,data)
  }

  getPackbudgetList$(id_pack:Number): Observable<any> {
    return this._httpService.get(`packPreview/${id_pack}/budget`)
  }

  getPackRedirectionInfo$(id_pack:Number): Observable<any> {
    return this._httpService.get(`packPreview/${id_pack}/redirection`)
  }

  getPackCampaingList$(key_pack:Number): Observable<any> {
    return this._httpService.get(`packPreview/campaignList/${key_pack}`)
  }

  changeBudgetPrixValue$(data: any) {
    return this._httpService.post(`packCreation/budget`, data)
  }

  updateCampaingBudgetPartition$(id_pack_campaign:number,data: any) {
    return this._httpService.put(`packCreation/packCampaign/${id_pack_campaign}`, data)
  }

  getFBUrl$(id_pack:Number): Observable<any> {
    return this._httpService.get(`packPreview/${id_pack}/packFburl`)
  }

  deleteFBUrl$(id_pack:Number) {
    return this._httpService.delete(`packCreation/packFburl/${id_pack}`)
  }
  
  savePackFBUrlInfo$(data: any) {
    return this._httpService.post(`packCreation/packFburl`, data)
  }

  savePackAgencyInfo$(data:any): Observable<any> {
    return this._httpService.post('packCreation/packAgency',data)
  } 

  updateCampaign$(data: any) {
    return this._httpService.put(`packCreation/campaign`, data)
  }

  getAdvertiserRedirectionURL$(data:any): Observable<any> {
    return this._httpService.post('packPreview/getRedirectionUrl',data)
  }

  getAdvertiserAddress$(data:any): Observable<any> {
    return this._httpService.post('packPreview/getAdvertiserAddress',data)
  }

  getInventaire$(data:any): Observable<any> {
    return this._httpService.post('packPreview/getPackCampaignInventaries',data)
  }

  savingPackData$(data:any): Observable<any> {
    // return this._httpService.post('packCreation/savePackData',data)
    return this._httpService.post('proposition/saveProposition',data)

  }
  
  addPackCiblage$(data: any){
    return this._httpService.post(`packCreation/packCiblage`, data)
  }

  checkingLevierWiseGalley$(data: any):  Observable<any> {
    return this._httpService.post(`packPreview/statusForgalleryCampaign`, data)
  }

  getDevisData$(data: any){
    return this._httpService.post(`bdc`,data)
  }

  updateAudience$(data:any,id_ciblage_predefined: any): Observable<any> {
    return this._httpService.put(`packCreation/ciblage/${id_ciblage_predefined}`,data)
  }

  getTableSummary$(data: any){
    return this._httpService.post(`bdc/tableSummary`,data)
  }

  validationEligibility$(key_proposition: any, idVoucher: any){
    return this._httpService.get(`bdc/validationEligibility/${key_proposition}?idVoucher=${idVoucher}`)
  }

  update_campaign_dates$(data:any,key_proposition:any): Observable<any> {
    return this._httpService.put(`bdc/updateCampaignDates/${key_proposition}`,data)
  }

  deleteExcelFile$(excel_id:any): Observable<any> {
    return this._httpService.delete(`packCreation/redirectionFile/${excel_id}`)
  }

  getExcelData$(excel_id:any): Observable<any> {
    return this._httpService.get(`packPreview/${excel_id}/redirectionFileUrl`)
  }

  getAllAudience$(id_intervenant: any): Observable<any> {
    return this._httpService.get(`packCreation/allCiblage/${id_intervenant}`)
  }
  getAllADvertiser$(agency_id :any): Observable<any> {
    return this._httpService.get(`packPreview/${agency_id}/allAdvertiser`)
  }
  addPackAdvertiser$(data: any){
    return this._httpService.post(`packCreation/packAdvertiser`, data)
  }
  getPackAdvertiser$(keyPack:any){
    return this._httpService.get(`packPreview/packAdvertiser/${keyPack}`)
  }
  deletePackAdvertiser$(data:any): Observable<any> {
    return this._httpService.post(`packPreview/packAdvertiser`,data)
  }
  
  savePackAdvertiserInfo$(data:any): Observable<any> {
    return this._httpService.post('packCreation/packAdvertiser',data)
  } 

  getAllAdvertisers(data:any): Observable<any> {
    return this._httpService.post('packPreview/getMultiAdvertisers',data)
  } 

  checkAdvData(data:any): Observable<any> {
  return this._httpService.post(`packPreview/checkAdvData`,data)
  }
  createStripeSession$(data:any): Observable<any> {
    return this._httpService.post(`bdc/stripSession`,data)
  }

  getPackAdvertiserZone$(key_pack :any): Observable<any> {
    return this._httpService.get(`packCreation/packAdvertiserZone/${key_pack}`)
  }
  
  insertPackAdvertiserZone$(data: any) {
    return this._httpService.post(`packCreation/packAdvertiserZone`,data)
  }
  
  updatePackAdvertiserZone$(data: any) {
    return this._httpService.put(`packCreation/packAdvertiserZone`,data)
  }

  removePackAdvertiserZone$(key_pack :any): Observable<any> {
    return this._httpService.delete(`packCreation/packAdvertiserZone/${key_pack}`)
  }

  validateVoucher$(data: any) {
    return this._httpService.post(`bdc/applyVoucher`,data)
  }

  updatevoucherInfo$(keyProposition:any,data:any): Observable<any> {
    return this._httpService.put(`proposition/${keyProposition}`,data)
  }

  getMultiPropositions$(data: any){
    return this._httpService.post(`proposition/getMultiPropositions`,data)
  }

  getMultiTableSummary$(data: any){
    return this._httpService.post(`bdc/multiPropTableSummary`,data)
  }

  multiValidationEligibility$(data: any){
    return this._httpService.post(`bdc/multiValidationEligibility`,data)
  }

  updateMultipleCampaigsDate$(data:any): Observable<any> {
    return this._httpService.post(`bdc/updateMultipleCampaigsDate`,data)
  }
  createMultiPropStripeSession$(data:any): Observable<any> {
    return this._httpService.post(`bdc/MultiPropStripeSession`,data)
  }

  multiPropositionLogin$(data:any): Observable<any> {
    return this._httpService.post('client/multiPropositionLogin',data)
  }

  getAdvertiserDetails$(id_advertiser:Number): Observable<any> {
    return this._httpService.get(`packPreview/${id_advertiser}/getAdvertiserDetails`)
  }

  validateMultiProposition$(data:any): Observable<any> {
    return this._httpService.post(`bdc/validateMultiProposition`,data)
  }

  public setHeaderVisibillity(value : boolean){
    this.HeaderVisible.next(value);
  }

  getHeaderVisibility(): Observable<boolean> {
    return this.HeaderVisible.asObservable();
  }

  public setValidDate(value : boolean){
    this.validDate.next(value);
  }
  
  getValidDate(): Observable<boolean> {
    return this.validDate.asObservable();
  }

  getMultiPropositionProgress$(data:any): Observable<any> {    
    return this._httpService.post('proposition/multiPropositionProgress',data)
  }

  saveMultiPropositionCron$(data:any): Observable<any> {    
    return this._httpService.post('proposition/insertMultiPropositionCron',data)
  }

}

export interface packInfo {
  id_pack : number,
  id_advertiser : number,
  id_gallery_group : number,
  start_date :any,
  end_date :any,
  re_url :string,
  adv_city_slider_km :Number,
  fb_url:string,
  selected_creatives:any,
  ciblage_data_arr :any,
  budget_data : any
  is_default_exists: number;
  api_reach:any;
  location_budget_data:any;
  };

  export interface packlevier {
    levier : any
  }